
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonSpinner,
  IonNote,
  IonText,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,

  onIonViewDidEnter,
 } from '@ionic/vue';

import { 
  checkmark, 
  addCircleOutline,
  ellipseOutline,
  checkmarkCircleOutline,
  checkmarkCircle,
  create,
  shuffle,
  ellipsisVertical,
  trashOutline,
  chevronBack,
}  from 'ionicons/icons';

import { defineComponent, ref, computed } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import { asDateObject } from '@/lib/api-endpoints'
import { differenceInCalendarDays } from 'date-fns';
import { showModal } from '@/lib/modal';
import { loadingController } from '@ionic/vue';
import t from '@/translation'
import { apiEndpoints } from '@/lib/api-endpoints';
import ExportButton from '@/components/ExportButton.vue';

export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    //IonListHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonItemDivider,
    IonLabel,
    //IonCheckbox,
    IonSpinner,
    IonNote,
    //IonReorder,
    //IonReorderGroup,
    //IonSelectOption,
    //IonMenu,
    //IonMenuButton,
    //IonPopover,
    IonText,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    

    //MInput,
    //MSelect,
    //MPassword,

    ExportButton,
  },
  setup(){

    const store = useStore();
    const router = useRouter();


    const weddingDateString = computed(() => {
      if (!(store.state.userData && store.state.userData.wedding)){
        return "Wedding date not set.";
      }
      // Apple browsers are pretty pedantic about having a 'T' between 
      // the time and date (unlike php which spits out a space).
      const weddingDate = asDateObject(store.state.userData.wedding.date);

      return weddingDate.toLocaleString('default', { 
        month: 'long', // "June" 
        day: 'numeric', // "1" 
        year: 'numeric' // "2019" 
      })
    });

    function inDays(dateStr: string){
      if (store.state.userData && store.state.userData.wedding){
        return differenceInCalendarDays(asDateObject(dateStr), asDateObject(store.state.userData.wedding.date));
      }
      return 0;
    }

    function formatTime(dateStr: string){
      const dt = asDateObject(dateStr);
      return dt.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true })
    }

    let purchaseInitiated = false;
    const showPurchase = ref(false);


    onIonViewDidEnter(()=>{
      purchaseInitiated = false;
      showPurchase.value = false;
      store.dispatch('getSchedule');
    });

    function onListItemClicked(idx: number){
      if (store.state.schedule[idx].hide_time){
        console.log(`Item at index ${idx} can't be edited`);
        return;
      }
      router.push(`/tabs/bigday/item/${store.state.schedule[idx].id}`);
    }

    function onDeleteClicked(id: number){
      store.dispatch('deleteScheduleItem', id);
    }

    function onAddItemClicked(){
      router.push('/tabs/bigday/item');
    }

    async function onRestorePurchase(){
      purchaseInitiated = false;
      const result = await store.dispatch('refreshPurchases');

      const loading = await loadingController.create({
            cssClass: 'api-request-loading',
            message: t("Loading previous purchases..."),
            duration: 10000,
          });
      await loading.present();

      if (! result.owned){
        showModal("Purchase Not Found", "No previous purchases could be found for your account.")
      }
      loading.dismiss();
    }
      
    const purchaseCancelled = computed(()=>{
      return store.state.premium.state == 'valid' && purchaseInitiated;
    })    

    async function onBuyNow(){
      const result = await store.dispatch('orderPremium');
      purchaseInitiated = true;

      const loading = await loadingController.create({
            cssClass: 'api-request-loading',
            message: t("Ordering Premium Version..."),
            duration: 10000,
          });
      await loading.present();

      console.log("Purchase complete:", result);

      if (result.state == "initiated"){
        //monitorPurchase();
      }

      loading.dismiss();
    }

    return {
      // computed
      weddingDateString,
      
      // icons
      checkmark,
      addCircleOutline,
      create,
      shuffle,
      ellipsisVertical,
      trashOutline,
      chevronBack,
      ellipseOutline,
      checkmarkCircleOutline,
      checkmarkCircle,

      // core functionality
      router,
      store,

      // functions
      onListItemClicked,
      onDeleteClicked,
      onAddItemClicked,
      formatTime,
      inDays,

      // mixed premium purchase attributes
      onRestorePurchase,
      onBuyNow,
      purchaseCancelled,
      apiEndpoints,
      showPurchase,
    }
  }
});
